<template>
  <div class="app">
    <div class="kd">
      <el-form 
        :model="ruleForm"
        ref="ruleForm"
        label-width="120px"
        class="demo-ruleForm">
        <el-form-item label="追跡番号" prop="mobile">
          <el-input class="textarea"
            type="textarea"
            :autosize="{ minRows: 5, maxRows: 20}"
            placeholder="RKJ始まりの伝票番号をご入力ください。1行に一件のみ、20件まで追跡可能です。"
            v-model="ruleForm.expressNo">
          </el-input>
        </el-form-item>
        <el-button type="success" :disabled="flag ? false : true" @click="get" icon="el-icon-search">貨物追跡開始</el-button>
      </el-form>
    </div>

    <div class="container">
     <el-collapse v-model="activeNames">
        <el-collapse-item  v-for="(express,index) in list" :title="`問い合わせ番号：`+express.expressNo" :key="index" :name="express.expressNo">
          <el-timeline :reverse="false">
            <el-timeline-item v-for="(item, index) in express.statuses"
              :key="index"
              :timestamp="item.time"
              :color="index==0?'#67C23A':''">
              {{ item.status }}
            </el-timeline-item>
          </el-timeline>
          <el-alert  v-if="express.statuses == undefined || express.statuses.length <= 0" title="お荷物のデータが登録されておりません。" :closable="false" type="info" center
    show-icon>
          </el-alert>
        </el-collapse-item>
      </el-collapse>
    </div>
    <el-backtop target=".container" :visibility-height="20" :bottom="10">
  </el-backtop>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  data() {
    
    return {
      activeNames: [],
      list: [],
      ruleForm:{
        expressNo:""
      },
      flag: true,
    };
  },
  mounted() {
  },
  created(){
  },
  methods: {
    req() {
      // if (this.ruleForm.type.trim().length == 0) this.ruleForm.type = "auto";
      if(this.ruleForm.expressNo.trim().length==0){
        this.$message.error("追跡番号をご入力してください。");
        return;
      }
      let noArr = this.ruleForm.expressNo.split('\n');
      let v = [];
      for (let index = 0; index < noArr.length; index++) {
        const e = noArr[index];
        if( e && e.trim().length > 0 ){
            v.push(e.trim());
        }
      }
      v = Array.from(new Set(v));
      this.activeNames=v;
      // console.log(v);
      // console.log(v.join(','));
      // this.dialogVisible = true;
      // this.kdMsg = [{status:'集荷完了',time:'2021-08-01 12:23:34'},
      // {status:'配達中',time:'2021-08-02 12:23:34'},
      // {status:'受取完了',time:'2021-08-07 12:23:34',color:'#0bbd87'}]
      // return;
      // this.excel(this.ruleForm.num);
      //'LTQ210310OR001,LTQ210315OR002,454545454'
      $.ajax({
        type: "post",
        url: "https://www.8jiex.com/api/logistics.do",
        data: {'expressNo':v.join(',')},
        dataType: "jsonp",
        success: (res) => {
          if (res.success) {
            this.list = res.data;
          } else {
            this.$message.error(res.message);
          }
          this.$refs.ruleForm.resetFields();
        },
        error: (err) => {
          console.log(err);
        },
      });
    },
    async get() {
      if (this.flag) {
        this.req();
        this.flag = false;
      }
      setTimeout(() => {
        this.flag = true;
      }, 1000 * 10);
    },
    cancel() {
      this.$refs.ruleForm.resetFields();
    },
  },
};
</script>

<style scoped>
.kd {
  margin-top: 30px;
   margin: 20px auto;
   width: 88%;
}
.el-button {
  margin: 10px 0 0 100px;
}
.el-form-item__content{
  width:100%
}
.el-input{
  width:100%
}
.container {
  margin: 20px auto;
  padding-left: 5px;
  width: 88%;
}
</style>
