<template>
    <kd></kd>
</template>

<script>
import kd from './components/kd.vue'

export default {
  components:{
    kd
  }
}
</script>

<style scoped>
</style>
